@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: local("Open Sans"),
    url(./assets/fonts/OpenSans-Bold.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
